@font-face {
  font-family: "NeoSansArabic";
  font-display: swap;
  src: local("NeoSansArabic"),
    url("./fonts/NeoSansArabic.ttf") format("truetype");
}

@font-face {
  font-family: "NeoSansArabic-lighter";
  font-display: swap;
  src: local("NeoSansArabic-lighter"),
    url("./fonts/NeoSansArabic-lighter.ttf") format("truetype");
}

@font-face {
  font-family: "NeoSansArabic-bold";
  font-display: swap;
  src: local("NeoSansArabic-bold"),
    url("./fonts/NeoSansArabic-bold.ttf") format("truetype");
}

$NeoSansArabic_font: NeoSansArabic;
$NeoSansArabic_font_lighter: NeoSansArabic-lighter;
$NeoSansArabic_font_bold: NeoSansArabic-bold;
$charcoal-grey: #383944;
$slate-grey: #5c5d60;
$brown-grey: #828282;
$cloudy-blue: #bcbcc5;
$very-light-pink: #e3e3e3;
$windows-blue: #3b9cbf;
$aqua-marine: #4fc2c3;
$aqua-marine2: #44adc1;
$ice-blue: #ecf4fb;

$break-small: 375px;
$break-medium: 768px;
$break-large: 1024px;

.body {
  direction: rtl;
  font-family: $NeoSansArabic_font;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
}

@keyframes fadein {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

// @keyframes fadeout {
//   from { opacity: 1; }
//   to   { opacity: 0; }
// }

// ----------- nav -------------
.nav.scrolled {
  background-color: rgba(255, 255, 255, 1) !important;
  box-shadow: 0 2px 10px 0 rgba(215, 215, 215, 0.33);
  transition: background-color 200ms linear;

  @media screen and (max-width: $break-medium) {
    background-color: rgba(255, 255, 255, 0.4) !important;
    box-shadow: none;
  }

  .logo {
    img {
      transform: scale(0.9);
    }
  }
}

.nav {
  width: 100%;
  margin: 0 auto 20px auto;
  padding: 20px 3%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 10;

  @media screen and (max-width: $break-medium) {
    display: flex;
    justify-content: space-between;

    &.on {
      z-index: 10;
      width: 100%;
      height: 100%;
      display: block;
      background-color: $charcoal-grey !important;
      position: fixed;
      margin: 0 auto;
      -webkit-animation: fadein 500ms;
      /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 500ms;
      /* Firefox < 16 */
      -ms-animation: fadein 500ms;
      /* Internet Explorer */
      -o-animation: fadein 500ms;
      /* Opera < 12.1 */
      animation: fadein 500ms;

      .logo {
        display: none;
      }
    }
  }

  .logo {
    cursor: pointer;
    text-align: right;
    padding: 0;
    padding-right: 0.3rem;

    img {
      width: 110px;
      transition: all 200ms ease-in-out;
    }

    @media screen and (max-width: $break-medium) {
      img {
        width: 90px;
      }
    }

    @media screen and (max-width: $break-large) and (min-width: $break-medium) {
      img {
        width: 110px;
      }
    }
  }

  .menu-toggle {
    width: 30px;
    height: 30px;
    display: none;

    @media screen and (max-width: $break-medium) {
      display: block;
      align-self: center;
      z-index: 1;
      position: absolute;
      top: 25px;
      left: 18px;

      &.on {
        // position: absolute;
        // top: 25px;
        // left: 15px;
      }

      &.on .one {
        -moz-transform: rotate(45deg) translate(5px, 10px);
        -ms-transform: rotate(45deg) translate(5px, 10px);
        -webkit-transform: rotate(45deg) translate(5px, 10px);
        transform: rotate(45deg) translate(5px, 10px);
        background: #fff;
      }

      &.on .two {
        opacity: 0;
      }

      &.on .three {
        -moz-transform: rotate(-45deg) translate(4px, -9px);
        -ms-transform: rotate(-45deg) translate(4px, -9px);
        -webkit-transform: rotate(-45deg) translate(4px, -9px);
        transform: rotate(-45deg) translate(4px, -9px);
        background: #fff;
      }
    }
  }

  .one,
  .two,
  .three {
    width: 100%;
    height: 2px;
    background: $charcoal-grey;
    margin: 8px auto;
    border-radius: 20px;
    backface-visibility: hidden;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .one {
    margin: 0 auto;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .navLinks {
    @media screen and (max-width: $break-medium) {
      display: none;

      &.on {
        display: flex;
        width: 100%;
        height: 75%;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }

    @media screen and (max-width: $break-large) and (min-width: $break-medium) {
      width: 90%;
    }

    .navLinksUl {
      list-style: none;
      width: 55%;
      display: flex;
      justify-content: space-around;
      justify-self: start;
      margin: 0 auto;
      padding: 0;

      @media screen and (max-width: $break-medium) {
        width: 100%;
        height: 85%;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        li {
          height: 100%;
        }
      }

      a {
        color: $brown-grey;
        font-size: 18px;
        font-family: $NeoSansArabic_font_lighter;
        letter-spacing: 0.4px;
        cursor: pointer;

        &.liActive {
          font-family: $NeoSansArabic_font;
          color: $aqua-marine;

          @media screen and (max-width: $break-medium) {
            color: #ffffff;
            font-family: $NeoSansArabic_font_lighter;
          }
        }

        @media screen and (max-width: $break-medium) {
          color: #ffffff;
          font-size: 20px;
        }
      }
    }

    .downloadLinkDiv {
      display: none;

      @media screen and (max-width: $break-medium) {
        display: flex;
        align-items: center;
        margin: 0 auto;
        padding-bottom: 2rem;
      }

      .downloadLink {
        display: none;
        text-align: center;

        @media screen and (max-width: $break-medium) {
          display: flex;
          justify-content: center;
          border-radius: 8px;
          box-shadow: 0 13px 35px -15px #25252b;
          background-image: linear-gradient(
            to left,
            $windows-blue,
            $aqua-marine
          );
          color: #fff;
          //
          width: 210px;
          margin: 0 auto;
          padding: 1rem 2rem;
        }
      }
    }

    .socialMediaLi {
      display: none;

      @media screen and (max-width: $break-medium) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        text-align: center;
        // margin-top: 5px;
      }

      ul {
        width: 50%;
        list-style: none;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        padding: 0;
      }
    }

    .socialMediaNav {
      display: none;

      @media screen and (max-width: $break-medium) {
        display: block;
        text-align: center;
        margin-top: 5px;

        ul {
          width: 50%;
          list-style: none;
          display: flex;
          justify-content: space-around;
          margin: 0 auto;
          padding: 0;
        }
      }
    }
  }

  .navLeft {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    div {
      text-align: center;

      @media screen and (max-width: $break-medium) {
        display: none;
      }

      @media screen and (max-width: $break-large) and (min-width: $break-medium) {
        width: 80px;
      }

      a {
        color: $windows-blue;
        font-size: 16px;
        padding-right: 5px;
        margin-left: 10px;
        cursor: pointer;

        @media screen and (max-width: $break-large) and (min-width: $break-medium) {
          font-size: 14px;
        }
      }

      img {
        margin-right: -26px;
      }
    }

    button {
      width: 130px;
      height: 45px;
      border-radius: 8px;
      box-shadow: 0 13px 35px -15px $aqua-marine;
      background-image: linear-gradient(to left, $windows-blue, $aqua-marine);
      border: none;
      font-size: 16px;
      letter-spacing: 0.4px;
      text-align: center;
      color: #ffffff;
      margin-right: 16px;

      @media screen and (max-width: $break-medium) {
        display: none;
      }

      @media screen and (max-width: $break-large) and (min-width: $break-medium) {
        width: 115px;
        height: 35px;
        font-size: 14px;
      }
    }
  }
}

// ---------- end nav ----------

// ------ startYourProject section ---------
.startYourProject {
  position: absolute;
  opacity: 0;

  .socialMedia {
    position: fixed;
    top: 77%;
    right: 2.5%;

    @media screen and (max-width: $break-medium) {
      display: none;
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      padding: 0;

      li {
        padding-bottom: 15px;

        img {
          width: 30px;
        }
      }
    }
  }

  &.active {
    padding: 30px;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $charcoal-grey;
    z-index: 20;

    opacity: 1;

    -webkit-animation: fadein 500ms;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 500ms;
    /* Firefox < 16 */
    -ms-animation: fadein 500ms;
    /* Internet Explorer */
    -o-animation: fadein 500ms;
    /* Opera < 12.1 */
    animation: fadein 500ms;

    @media screen and (max-width: $break-medium) {
      padding: 20px;
      overflow: scroll;
    }
  }

  .startYourProjectSection {
    display: none;

    &.active {
      display: block;
      position: relative;
      top: 46%;
      transform: translateY(-50%);

      @media screen and (max-width: $break-medium) {
        top: 47%;
      }

      @media screen and (max-width: 360px) {
        top: 49%;
      }
    }
  }

  .infoSection {
    height: 100%;
    -webkit-animation: fadein 500ms;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 500ms;
    /* Firefox < 16 */
    -ms-animation: fadein 500ms;
    /* Internet Explorer */
    -o-animation: fadein 500ms;
    /* Opera < 12.1 */
    animation: fadein 500ms;

    @media screen and (max-width: $break-medium) {
      height: 80%;
    }

    .main {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 90%;

      @media screen and (max-width: $break-medium) {
        height: 70%;
        text-align: center;
      }
    }

    p {
      display: none;

      @media screen and (max-width: $break-medium) {
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        display: block;
        // padding-bottom: 20%;
      }
    }

    #projectForm {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      h1 {
        display: block;
        font-size: 45px;
        color: #fff;
        align-self: start;
        font-family: $NeoSansArabic_font_bold;
        margin-bottom: 1.5rem;

        @media screen and (max-width: $break-medium) {
          // display: none;
          font-size: 30px;
        }
      }

      input,
      textarea {
        font-family: $NeoSansArabic_font_lighter;
        color: #ffffff;
        font-size: 18px;
        display: block;
        width: 532px;
        // height: 50px;
        border-radius: 8px;
        border: solid 2.9px #ffffff;
        background-color: $charcoal-grey;
        padding: 10px;
        margin: 5px 0;
        resize: none;

        @media screen and (max-width: $break-medium) {
          border: solid 1px #ffffff;
          width: 196px;
          // height: 40px;
          font-size: 14px;
        }

        input {
          height: 50px;

          @media screen and (max-width: $break-medium) {
            height: 40px;
          }
        }

        &.error {
          border-color: #f56659;
          background: url("./images/ic-error.svg") no-repeat left;
          -webkit-appearance: none;
          background-position-x: 15px;
          background-size: 18px;
        }

        &.error#countryCode {
          background-position-x: 8px;
        }

        &.error#mobileNumber {
          background-position-x: 11px;
        }
      }

      .errorMessage {
        color: #f56659;
        text-align: right;
        display: block;
        font-size: 14px;

        @media screen and (max-width: $break-medium) {
          font-size: 12px;
        }
      }

      .errorMessage.codeMessage {
        margin-right: 1rem;
      }

      input::-webkit-inner-spin-button,
      input::-webkit-outer-spin-button textarea::-webkit-inner-spin-button,
      textarea::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
        -moz-appearance: textfield !important;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }

      .input,
      .textarea-input {
        width: 693px;

        @media screen and (max-width: $break-medium) {
          width: 300px;
          // height: 40px;
        }
      }

      .input {
        @media screen and (max-width: $break-medium) {
          // width: 300px;
          height: 40px;
        }
      }

      input:focus,
      textarea:focus {
        outline: none !important;
      }

      input::placeholder,
      textarea::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #fff;
        opacity: 1;
        /* Firefox */
        height: 100%;

        @media screen and (max-width: $break-medium) {
          // height: 40px;
        }
      }

      select {
        width: 693px;
        height: 52px;
        background-color: #fff !important;
        color: $charcoal-grey;
        font-size: 18px;
        border: none;
        border-radius: 8px;
        outline: none;
        appearance: button;
        background: url("./images/ic-dropdown.svg") no-repeat left;
        -webkit-appearance: none;
        background-position-x: 15px;
        background-size: 18px;
        padding: 0 25px;
        margin: 5px 0;

        option {
          direction: ltr;
        }

        &.error {
          // border-color: #f56659;
          background: url("./images/ic-error.svg") no-repeat left;
          -webkit-appearance: none;
          background-position-x: 13px;
          background-size: 18px;
          border: solid 2.9px #f56659;
        }

        @media screen and (max-width: $break-medium) {
          // width: 300px;
          height: 39px;
          background-position-x: 7px;
          background-size: 10px;
          padding: 0 10px;
          font-size: 14px;
          width: 145px;
        }
      }

      button {
        width: 154px;
        height: 50px;
        border-radius: 8px;
        box-shadow: 0 13px 35px -15px #25252b;
        background-image: linear-gradient(to left, $windows-blue, $aqua-marine);
        color: #fff;
        font-family: $NeoSansArabic_font_bold;
        font-size: 20px;
        border: none;
        margin: 20px 0;
        align-self: flex-end;

        @media screen and (max-width: $break-medium) {
          font-size: 18px;
          width: 184px;
          height: 60px;
          margin: 0;
          // margin-top: 70px;
          align-self: center;
          margin-top: 1rem;
        }
      }

      #mobileNumber {
        @media screen and (max-width: $break-medium) {
          height: 40px;
        }
      }

      #countryCode {
        width: 145px;
        margin-right: 1rem;
        direction: ltr;
        text-align: right;

        @media screen and (max-width: $break-medium) {
          width: 86px;
          height: 40px;
        }
      }
    }
  }

  .cancel {
    position: relative;
    z-index: 20;

    @media screen and (max-width: $break-medium) {
      width: 25px;
    }
  }

  // .next {
  //   width: 174px;
  //   height: 50px;
  //   border-radius: 8px;
  //   background-color: $windows-blue;
  //   border: none;

  //   font-family: $NeoSansArabic_font_bold;
  //   font-size: 20px;
  //   letter-spacing: 0.5px;
  //   color: #ffffff;

  //   @media screen and (max-width: $break-medium) {
  //     font-size: 14px;
  //     height: 35px;
  //     margin-top: 10px;

  //   }
  // }
}

.flex {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $break-medium) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

.sentSuccesfullySection {
  display: none;

  &.active {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 20;
    height: 100vh;
    width: 100vw;
    background-image: linear-gradient(
      to left,
      $windows-blue,
      $aqua-marine
    ) !important;
    padding: 30px;
    -webkit-animation: fadein 500ms;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 500ms;
    /* Firefox < 16 */
    -ms-animation: fadein 500ms;
    /* Internet Explorer */
    -o-animation: fadein 500ms;
    /* Opera < 12.1 */
    animation: fadein 500ms;

    @media screen and (max-width: $break-medium) {
      padding: 20px;
    }

    .succesfullyContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 90%;

      @media screen and (max-width: $break-medium) {
        height: 100%;
      }

      div {
        margin: 30px;
      }

      p {
        font-size: 24px;
        line-height: 1.33;
        letter-spacing: 0.5px;
        text-align: center;
        color: #fff;

        @media screen and (max-width: $break-medium) {
          font-size: 18px;
        }
      }

      button {
        width: 180px;
        height: 60px;
        border-radius: 8px;
        box-shadow: 0 13px 35px -15px $aqua-marine;
        background-color: $windows-blue;
        border: none;
        font-family: $NeoSansArabic_font_bold;
        font-size: 20px;
        letter-spacing: 0.4px;
        text-align: center;
        color: #ffffff;
        margin-top: 10px;

        @media screen and (max-width: $break-medium) {
          font-size: 18px;
        }
      }
    }

    .cancel {
      @media screen and (max-width: $break-medium) {
        width: 25px;
      }
    }
  }

  .socialMedia {
    ul {
      width: 100%;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      padding: 0;

      li {
        margin: 10px;

        a {
          img {
            width: 45px;
          }
        }
      }
    }
  }
}

// -------- end startYourProject section -------

// -------- dots nav --------
nav {
  position: fixed;
  /*left: 50%;*/
  top: 40%;
  // right: 0;
  right: 1.8%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sectionName {
    color: $cloudy-blue;
    margin: 0;
    margin-top: 1%;
    border-radius: 0;
    // align-self: flex-start;
    margin-right: 15px;
    // width: 80px;
    text-align: right;
    height: 100px;
    background: none;
    writing-mode: tb-rl;
    transform: rotate(180deg);

    // transform: rotate(270deg);
    &:hover {
      background: none;
    }

    &:not([href]):not([tabindex]) {
      color: $cloudy-blue;
    }
  }

  .nextYear,
  .previousYear,
  .previousSection,
  .nextSection {
    color: $cloudy-blue;
    margin: 0;
    border-radius: 0;
    // margin-right: 18%;
    margin-right: 15px;
    // width: 80px;
    background: none;
    // transform: rotate(270deg);
    writing-mode: tb-rl;
    transform: rotate(180deg);
    margin-top: 0;

    // height: 100px;
    &:hover {
      background: none;
    }

    &:not([href]):not([tabindex]) {
      color: $cloudy-blue;
    }
  }

  .nextYear {
    margin-top: 0;
  }

  .previousYear {
    margin-bottom: 35px;
    // text-align: right;
  }
}

nav a {
  border-radius: 100%;
  width: 8px;
  height: 8px;
  margin-bottom: 5px;
  background: $very-light-pink;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  text-decoration: none;
  color: black;

  @media screen and (max-width: $break-medium) {
    display: none;
  }
}

nav a.active {
  background: $aqua-marine;
  color: white;
  width: 14px;
  height: 14px;
}

nav a:hover {
  background: $aqua-marine;
}

.startYourProjectNav {
  position: fixed;
  /*left: 50%;*/
  top: 30%;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  height: auto;

  @media screen and (max-width: $break-medium) {
    flex-direction: row;
    justify-content: center;
    top: 94%;
    width: 100%;
    margin: 0 auto;
  }

  .previousSection,
  .nextSection {
    color: $cloudy-blue;
    margin: 0;
    border-radius: 0;
    margin-right: 18%;
    width: 120px;
    background: none;
    transform: rotate(270deg);
    margin-top: 0;

    @media screen and (max-width: $break-medium) {
      transform: rotate(0deg);
      margin-right: 0;
      height: 100%;
      text-align: center;
      font-size: 14px;
      width: 100px;
    }

    &:hover {
      background: none;
    }

    &:not([href]):not([tabindex]) {
      color: $cloudy-blue;
    }
  }

  .previousSection {
    margin-bottom: 80%;

    @media screen and (max-width: $break-medium) {
      margin: 0;
    }
  }

  .nextSection {
    margin-top: 35%;

    @media screen and (max-width: $break-medium) {
      margin: 0;
    }
  }

  a {
    border-radius: 100%;
    width: 8px;
    height: 8px;
    margin-bottom: 5px;
    background: $very-light-pink;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    text-decoration: none;
    color: black;

    @media screen and (max-width: $break-medium) {
      margin-bottom: 0;
      margin: 5px;
    }

    &.on {
      background: $aqua-marine;
      color: white;
      width: 14px;
      height: 14px;
    }
  }

  a:hover {
    background: $aqua-marine;
  }
}

// ----------------- end dots nav --------------
.startYourProjectButton {
  width: 130px;
  height: 45px;
  border-radius: 8px;
  box-shadow: 0 13px 35px -15px $aqua-marine;
  background-image: linear-gradient(to left, $windows-blue, $aqua-marine);
  border: none;
  font-family: NeoSansArabic;
  font-size: 16px;
  letter-spacing: 0.4px;
  text-align: center;
  color: #ffffff;
  display: none;
  position: relative;
  @media screen and (max-width: $break-medium) {
    position: fixed;
    bottom: 3%;
    right: 5%;
    width: 102px;
    height: 39px;
    box-shadow: 0 5px 11px 0 $cloudy-blue;
    font-size: 12px;
    z-index: 1;
    display: block;
  }

  @media screen and (max-width: $break-large) and (min-width: $break-medium) {
    width: 115px;
    height: 35px;
    font-size: 14px;
  }
}

.landPage {
  -webkit-animation: fadein 600ms;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 600ms;
  /* Firefox < 16 */
  -ms-animation: fadein 600ms;
  /* Internet Explorer */
  -o-animation: fadein 600ms;
  /* Opera < 12.1 */
  animation: fadein 600ms;
}

// ----------- home ----------
.home {
  height: 100vh;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: $break-medium) {
    background: #ffffff;
    height: 90vh;
  }

  .homeHeader {
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @media screen and (max-width: $break-medium) {
      height: 100%;
    }

    .header {
      br {
        display: none;

        @media screen and (max-width: $break-medium) {
          display: block;
        }
      }
    }

    // .header-mobile {
    //   display: none;
    //   @media screen and (max-width: $break-medium) {
    //     display: block;
    //    }
    // }

    h1 {
      background: -ms-linear-gradient(to left, $windows-blue, $aqua-marine),
        -ms-linear-gradient(to bottom, $windows-blue, $windows-blue);
      background: -webkit-linear-gradient(to left, $windows-blue, $aqua-marine),
        -webkit-linear-gradient(to bottom, $windows-blue, $windows-blue);
      background: linear-gradient(to left, $windows-blue, $aqua-marine),
        linear-gradient(to bottom, $windows-blue, $windows-blue);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-size: 55px;
      font-family: $NeoSansArabic_font_bold;
      margin: 0;
      padding: 10%;

      .words {
        text-align: center;
        // height: 100%;
        margin: 0 5px;

        div {
          margin: 0 3px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: $aqua-marine2;

          span {
            display: block;
            text-align: center;
            padding-bottom: 5px;
          }
        }
      }

      @media screen and (max-width: $break-medium) {
        font-size: 42px;
      }

      @media screen and (max-width: $break-small) {
        padding-bottom: 8px;
      }
    }
  }

  .socialMedia {
    position: fixed;
    top: 70%;
    right: 1.6%;
    z-index: 2;

    @media screen and (max-width: $break-medium) {
      display: none !important;
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      padding: 0;

      li {
        padding-bottom: 15px;

        img {
          width: 30px;
        }
      }
    }
  }

  .volunteerMobile {
    display: none;

    @media screen and (max-width: $break-medium) {
      display: block;
      position: absolute;
      width: 60%;
      bottom: 0;
      left: 0;
    }

    @media screen and (max-width: $break-large) and (min-width: $break-medium) {
      width: 40%;
    }
  }

  .transparent {
    opacity: 0 !important;
    pointer-events: none;
  }

  .transition {
    -webkit-transition: width 350ms ease-in-out, left 350ms ease-in-out,
      bottom 350ms ease-in-out, right 350ms ease-in-out, top 350ms ease-in-out,
      // transform 250ms ease-in-out,
      opacity 350ms ease-in-out;
    -ms-transition: width 350ms ease-in-out, left 350ms ease-in-out,
      bottom 350ms ease-in-out, right 350ms ease-in-out, top 350ms ease-in-out,
      // transform 250ms ease-in-out,
      opacity 350ms ease-in-out;
    -moz-transition: width 350ms ease-in-out, left 350ms ease-in-out,
      bottom 350ms ease-in-out, right 350ms ease-in-out, top 350ms ease-in-out,
      // transform 250ms ease-in-out,
      opacity 350ms ease-in-out;
    transition: width 350ms ease-in-out, left 350ms ease-in-out,
      bottom 350ms ease-in-out, right 350ms ease-in-out, top 350ms ease-in-out,
      // transform 250ms ease-in-out,
      opacity 350ms ease-in-out;
  }

  .sawah,
  .sawahMobile {
    position: absolute;
    right: 5%;
    width: 35%;
    top: 15%;
    user-select: none;
    cursor: pointer;

    div {
      position: relative;
      z-index: -1;
    }

    &.hover {
      width: 55%;
      top: 40%;
      right: 5%;
    }

    @media screen and (max-width: $break-medium) {
      width: 70%;
      top: 20%;
      right: 1%;
    }

    @media screen and (max-width: $break-large) and (min-width: $break-medium) {
      width: 40%;
    }
  }

  .scale-sawah {
    width: 37% !important;
    transition: width 250ms ease-in !important;
  }

  .sarahah {
    position: absolute;
    right: 15%;
    top: 45%;
    width: 10%;
    user-select: none;
    cursor: pointer;

    @media screen and (max-width: $break-large) and (min-width: $break-medium) {
      right: 10%;
    }

    &.hover {
      width: 30%;
      top: 45%;
      right: 12%;
    }
  }

  .scale-sarahah {
    width: 12% !important;
    transition: width 250ms ease-in !important;
  }

  .marsool {
    position: absolute;
    right: 2%;
    bottom: 0;
    width: 45%;
    user-select: none;
    cursor: pointer;

    @media screen and (max-width: $break-large) and (min-width: $break-medium) {
      bottom: 0.9%;
    }

    &.hover {
      right: -5%;
      bottom: 5%;
      width: 80%;
      z-index: 2;
    }
  }

  .scale-marsool {
    width: 47% !important;
    transition: width 250ms ease-in !important;
  }

  .volunteer {
    position: absolute;
    width: 9%;
    bottom: 4%;
    right: 3.5%;
    user-select: none;
    cursor: pointer;

    &.hover {
      right: 50%;
      bottom: 10%;
      width: 30%;
      z-index: 1;
    }

    @media screen and (max-width: $break-large) and (min-width: $break-medium) {
      bottom: 3.4%;
    }
  }

  .scale-volunteer {
    width: 10% !important;
    transition: width 250ms ease-in !important;
  }

  .jarir {
    position: absolute;
    bottom: 3.5%;
    left: 20%;
    width: 40%;
    user-select: none;
    cursor: pointer;

    &.hover {
      left: 15%;
      bottom: 5%;
      width: 60%;
      z-index: 2;

      @media screen and (max-width: 1200px) {
        left: 9%;
      }
    }
  }

  .scale-jarir {
    width: 41.5% !important;
    transition: width 250ms ease-in !important;
  }

  .city {
    position: absolute;
    width: 35%;
    bottom: 4.4%;
    left: 0;
    user-select: none;
  }

  .scth {
    position: absolute;
    width: 19%;
    bottom: 4.3%;
    left: -3%;
    user-select: none;
    cursor: pointer;

    &.hover {
      left: 25%;
      bottom: 8.9%;
      width: 30%;
      z-index: 1;
    }
  }

  .scale-scth {
    width: 20% !important;
    transition: width 250ms ease-in !important;
  }

  .land {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4%;
    background-color: $ice-blue;
    user-select: none;

    &.marsoolClick,
    &.volunteerClick {
      height: 12%;
    }

    &.jarirClick {
      height: 6%;
    }

    &.sawahClick,
    &.musanedClick,
    &.scthClick,
    &.sarahahClick {
      height: 9%;
    }
  }

  .line {
    position: absolute;
    bottom: 3.7%;
    left: 0;
    width: 30%;
    height: 0.8%;
    background-color: #dcecf8;
    user-select: none;
  }

  .musaned {
    position: absolute;
    width: 3%;
    bottom: 4.3%;
    left: 19%;
    user-select: none;
    cursor: pointer;

    &.hover {
      left: 37%;
      bottom: 8.5%;
      width: 8%;
      z-index: 2;

      @media screen and (max-width: 1200px) {
        left: 45%;
      }
    }
  }

  .scale-musaned {
    width: 3.3% !important;
    // transform: scale(1.05);
    transition: width 250ms ease-in !important;
  }

  .groupHome {
    position: absolute;
    width: 10%;
    bottom: 47%;
    left: 6.5%;
    user-select: none;
  }

  @media screen and (max-width: $break-medium) {
    .sarahah,
    .marsool,
    .volunteer,
    .jarir,
    .city,
    .scth,
    .land,
    .musaned,
    .groupHome,
    .line {
      display: none;
    }
  }
}

.bg {
  display: none;
  user-select: none;
  z-index: -2;
  -webkit-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.jarirBg {
  max-width: 90%;
  max-height: 90%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -2;
}

.sarahaBg {
  max-width: 95%;
  max-height: 95%;
  position: absolute;
  top: 10%;
  left: 0;
  z-index: -2;
}

.marsoolBg {
  max-width: 65%;
  max-height: 65%;
  position: absolute;
  bottom: 11%;
  left: -5%;
  z-index: -1;
}

.marsoolProject,
.jarirProject,
.volunteerProject,
.sawahProject,
.musanedProject,
.sarahahProject,
.scthProject,
.projectDetails {
  // position: relative;;
  // width: 330px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  // width: 50rem;
  // width: 100%;
  // width: 400px;
  // height: 380px;
  // height: 100%;
  // height: 450px;
  overflow: auto;

  border-radius: 10px;
  box-shadow: 0 43px 54px -32px $cloudy-blue;
  background-color: #ffffff;
  position: absolute;
  z-index: 1;
  text-align: right;
  padding: 0.5rem 1.5rem;

  @media screen and (max-width: $break-medium) {
    display: none;
  }

  &.off {
    display: none;
  }

  h1 {
    opacity: 0.17;
    font-size: 60px;
    font-family: $NeoSansArabic_font_bold;
    text-align: right;
    color: $very-light-pink;
    position: absolute;
    top: -20%;
    right: -35%;
    margin: 20px;
  }

  .projectHeader {
    // display: flex;
    // // justify-content: space-around;
    // width: 100%;
    // width: 200px;
    // height: 150px;

    .projectLogo {
      width: 180px;
      // width: 120px;
      max-height: 100%;
      // max-width: 100%;
      // max-height: 100%;
      // width: 25%;
      // height: 100%;
      padding: 1rem 0;
      // margin:
    }

    .serviceLogo {
      width: 25px;
    }
  }

  h5 {
    font-family: $NeoSansArabic_font_bold;
    font-size: 18px;
    color: $cloudy-blue;
    // padding: .5rem 0;
    padding-top: 0.5rem;
  }

  h2 {
    font-size: 32px;
  }

  p {
    // margin: 15px;
    // font-family: $NeoSansArabic_font_lighter;
    font-size: 16px;
    line-height: 1.33;
    color: $brown-grey;
    padding: 0.5rem 0;
  }

  // .stores {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   z-index: 100;

  //   img {
  //     width: 110px;
  //   }

  //   .appStore {
  //     width: 105px;
  //     margin-left: 10px;
  //   }
  // }

  .projectServices {
    border-top: 1px solid #ededed;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 1rem 0;
    // margin: 0 .2rem;

    .service {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.3rem 0;

      // padding-left: .5rem;
      img {
        //  width: 38%;
        width: 42px;
        border-radius: 8px;
        background-color: #f3f8fd;
        padding: 0.5rem;
        //  margin: 0 .5rem;
        margin-left: 0.5rem;
        //  margin: 0 auto;
        //  text-align: center;
      }

      span {
        font-size: 11px;
        color: $cloudy-blue;
        // font-family: $NeoSansArabic_font_lighter;
      }
    }

    // .service:nth-child(3n) {
    //   padding-left:0;
    // }
  }
}

// $break-x-large: 1500px;

.marsoolProject {
  left: 20%;
  // left: 15%;
  bottom: 17%;
  z-index: 1;

  @media screen and (max-width: 1400px) {
    left: 15%;
    bottom: 20%;
  }

  @media screen and (max-width: 1200px) {
    left: 10%;
    bottom: 22%;
  }

  @media screen and (max-width: $break-medium) {
    left: 13%;
    bottom: 25%;
  }
}

.jarirProject {
  right: 12%;
  bottom: 10%;
  z-index: 1;

  @media screen and (max-width: 1400px) {
    right: 5%;
  }

  // @media screen and (max-width: 1200px) {
  //   right: 2%;
  // }

  h1 {
    opacity: 0.17;
    font-size: 70px;
    font-family: $NeoSansArabic_font_bold;
    text-align: right;
    color: $very-light-pink;
    position: absolute;
    top: -20%;
    right: -22%;
    margin: 20px;
  }
}

.volunteerProject {
  right: 23%;
  bottom: 20%;

  @media screen and (max-width: 1400px) {
    // left: 15%;
    bottom: 16%;
  }

  @media screen and (max-width: 1200px) {
    right: 18%;
    // bottom: 15%;
  }

  // @media screen and (max-width: $break-medium) {
  //   left: 13%;
  //   bottom: 25%;
  // }
}

.sawahProject {
  right: 53%;
  bottom: 15%;
  z-index: 2;
}

.musanedProject {
  right: 25%;
  bottom: 15%;
  z-index: 1;

  @media screen and (max-width: 1400px) {
    right: 20%;
  }

  @media screen and (max-width: 1200px) {
    right: 7%;
    bottom: 12%;
  }

  // @media screen and (max-width: $break-medium) {
  //   left: 13%;
  //   bottom: 25%;
  // }
}

.scthProject {
  right: 21%;
  bottom: 13%;
  z-index: -1;

  @media screen and (max-width: 1400px) {
    right: 13%;
  }

  // @media screen and (max-width: 1200px) {
  //   right: 17%;
  // }
  // @media screen and (max-width: $break-medium) {
  //   right: 21%;
  // }
}

.sarahahProject {
  right: 45%;
  bottom: 15%;
  z-index: 2;
}

// --------------- end home ---------

.ourWay,
.ourVision,
.ourServices,
.ourClients,
.teamWorks {
  height: 100vh;
  position: relative;
  box-sizing: border-box;
}

.ourWay,
.ourServices,
.ourClients,
.ourVision {
  h1 {
    margin: 0;
    margin-right: 10%;
    padding-top: 8%;
    padding-bottom: 1rem;
    color: $aqua-marine2;
    font-family: $NeoSansArabic_font_bold;
    font-size: 55px;

    @media screen and (max-width: $break-medium) {
      margin-right: 0;
      padding-top: 100px;
      font-size: 35px;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }
}

.d-none {
  // display: none !important;
  opacity: 0;
  height: 5px;
  // display: none !important ;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  // animation: fadeout .5s ease-in-out;
}

// ------------ ourVision ----------
.ourVision {
  background-image: linear-gradient(
    to bottom,
    $ice-blue,
    rgba(255, 255, 255, 0)
  );

  @media screen and (max-width: $break-medium) {
    background: #ffffff;
    height: 100%;
    padding-top: 100px;
  }

  .ourVisionContent {
    height: 70%;
    width: 50%;
    display: flex;
    text-align: right;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 10%;

    @media screen and (max-width: $break-medium) {
      height: 100%;
      width: 75%;
      margin: 0 auto;
      align-items: center;
      padding-right: 0;
      text-align: center;
    }

    h1 {
      margin: 0;
    }

    img {
      width: 150px;

      @media screen and (max-width: $break-medium) {
        width: 120px;
      }
    }

    p {
      font-size: 20px;
      padding-top: 1%;
      font-family: $NeoSansArabic_font_lighter;
      font-weight: bold;
      color: $brown-grey;
      line-height: 1.67;

      @media screen and (max-width: $break-medium) {
        font-family: $NeoSansArabic_font;
        font-weight: normal;
        font-size: 18px;
        line-height: 1.94;
      }
    }
  }

  .ourVisionImg {
    position: absolute;
    bottom: 0;
    left: 0;

    @media screen and (max-width: $break-medium) {
      display: none;
    }

    img {
      width: 70%;
    }
  }
}

// ------------ end ourVision ----------

// ------------ ourServices ----------
.ourServices {
  height: 550px;
  text-align: right;

  .ourServices-l-outer {
    width: 100%;
    height: 500px;
    overflow-x: auto;
    overflow-y: hidden;
    right: 8%;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    .ourServices-l {
      position: absolute;
      right: 2%;
      display: flex;
      -webkit-transition: right 500ms;
      -moz-transition: right 500ms;
      -ms-transition: right 500ms;
      transition: right 500ms;
      user-select: none;
      box-sizing: border-box;
      // overflow-y: hidden;
    }
  }

  .ourServices-s {
    display: none;
  }

  @media screen and (max-width: $break-medium) {
    text-align: center;
    height: 100%;

    .ourServices-l-outer {
      display: none;
    }

    .ourServices-s {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .eachService {
    width: 235px;
    height: 340px;
    border-radius: 10px;
    padding: 15px;
    margin: 10px;
    // opacity: 1;
    // -webkit-transition: all .5s ease-in-out;
    // -moz-transition: all .5s ease-in-out;
    // -o-transition: all .5s ease-in-out;
    // transition: all .5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #ffffff;
    box-sizing: border-box;

    @media screen and (max-width: $break-medium) {
      align-items: center;
      height: 100%;
      width: 70%;
      margin: 10px auto;
    }

    img {
      width: 30px;
      margin: 10px 0;
      padding-bottom: 10px;

      @media screen and (max-width: $break-medium) {
        padding-bottom: 8px;
        width: 50px;
      }
    }

    h5 {
      font-size: 18px;
      font-family: $NeoSansArabic_font_bold;
      color: $slate-grey;
      padding-bottom: 10px;

      @media screen and (max-width: $break-medium) {
        padding-bottom: 5px;
        font-weight: bold;
      }
    }

    p {
      font-size: 14px;
      color: $cloudy-blue;
      line-height: 2;
      -webkit-transition: all 300ms ease-in;
      -moz-transition: all 300ms ease-in;
      -o-transition: all 300ms ease-in;
      transition: all 300ms ease-in;

      @media screen and (max-width: $break-medium) {
        display: block;
        font-size: 16px;
        color: $brown-grey;
        line-height: 1.89;
      }
    }

    .serviceButton {
      display: flex;
      justify-content: center;
    }

    button {
      width: 90%;
      height: 45px;
      border-radius: 8px;
      border: none;
      box-shadow: 0 13px 35px -15px $aqua-marine;
      background-image: linear-gradient(to left, $windows-blue, $aqua-marine);
      color: #ffffff;
      font-size: 18px;
      opacity: 0;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;

      @media screen and (max-width: $break-medium) {
        display: none;
      }
    }
  }

  .eachService-empty {
    width: 200px;
    height: 340px;

    @media screen and (max-width: $break-medium) {
      display: none;
    }
  }

  .eachService:hover {
    box-shadow: 0 43px 54px -32px $cloudy-blue;

    @media screen and (max-width: $break-medium) {
      box-shadow: none;
    }

    p {
      color: $brown-grey;

      @media screen and (max-width: $break-medium) {
        line-height: 1.89;
        font-family: $NeoSansArabic_font;
      }
    }

    button {
      opacity: 1;
    }
  }
}

@mixin white-gradient {
  background: -ms-linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
  background: -o-linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
  background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
}

.ourWay {
  text-align: right;
  height: 100%;

  // padding-bottom: 8%;
  .ourWayContent {
    display: flex;
    padding-bottom: 0%;

    @media screen and (max-width: $break-medium) {
      display: block;
    }
  }

  .ourWayImgDiv {
    width: 40%;
    height: 500px;
    position: absolute;
    // bottom: 10%;
    left: 0;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: $break-medium) {
      height: 400px;
      width: 100%;
      position: relative;
    }
  }

  .ourWayImg {
    opacity: 0;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    -webkit-transition: all 300ms ease-in;
    -moz-transition: all 300ms ease-in;
    -o-transition: all 300ms ease-in;
    transition: all 300ms ease-in;

    &.last-way {
      margin: 0 auto;
      left: 15%;
    }

    &.on {
      opacity: 1;
    }

    &,
    &.last-way {
      @media screen and (max-width: $break-medium) {
        max-width: 95%;
        max-height: 95%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -10;
      }
    }

    &.first-way {
      @media screen and (max-width: $break-medium) {
        top: 0;
        left: 0;
        transform: translate(0, 0);
      }
    }
  }

  @media screen and (max-width: $break-medium) {
    text-align: center;
    height: 100%;
  }

  // .after {
  //   position: absolute;
  //   background-color: red;
  //   height: 100px;
  //   width: 500px;
  //   bottom: 10%;
  //   right: 6%;

  //   @include white-gradient;
  //   content: "";
  //   z-index: 2;

  //   @media screen and (max-width: $break-medium) {
  //     display: none;
  //   }
  // }

  .sliderDiv {
    width: 60%;
    position: relative;
    margin-top: 2%;

    @media screen and (max-width: $break-medium) {
      width: 100%;
      position: unset;
      // -ms-transform: none;
      // transform: none;
    }

    &::before,
    &::after {
      position: absolute;
      background-color: white;
      // height: 100px;
      width: 100%;
      right: 6%;

      @include white-gradient;
      content: "";
      z-index: 1;

      @media screen and (max-width: $break-medium) {
        display: none;
      }
    }

    &::after {
      bottom: 0%;
      height: 100px;
    }

    &::before {
      height: 50px;
      top: 0;
      transform: rotateZ(180deg);
    }
  }

  .slider {
    overflow-y: auto;
    // top: 10%;
    right: 6%;
    width: 100%;
    height: 400px;
    position: relative;

    @media screen and (max-width: $break-medium) {
      top: 3%;
      right: 0;
      width: 100%;
      -webkit-overflow-scrolling: touch;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .sliderContent {
      top: 10%;
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 6%;
      -webkit-user-select: none;
      -moz-user-select: moz-none;
      -ms-user-select: none;
      user-select: none;

      @media screen and (max-width: $break-medium) {
        flex-direction: row;
        right: 6%;
        top: 0;
        padding-left: 6%;
        // height: auto;
        position: absolute;
      }
    }
  }

  .eachWay {
    display: flex;
    justify-content: flex-start;
    height: 160px;

    @media screen and (max-width: $break-medium) {
      flex-direction: column;
      height: auto;
      // width: 320px;
      width: 90vw;
    }

    &.active {
      align-items: center;

      .eachWayNum {
        h1 {
          padding-left: 25px;
          color: $aqua-marine;
          font-size: 130px;
          font-family: $NeoSansArabic_font_bold;

          @media screen and (max-width: $break-medium) {
            font-size: 45px;
            padding-left: 0;
          }
        }

        p {
          display: none;
        }
      }

      .eachWayContent {
        h3,
        p {
          opacity: 1;
        }

        h3 {
          color: $slate-grey;

          @media screen and (max-width: $break-medium) {
            text-align: center;
          }
        }
      }
    }

    .eachWayNum {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      h1 {
        padding: 0;
        padding-left: 20px;
        font-size: 110px;
        margin: 0;
        text-align: center;
        color: $ice-blue;
        margin-bottom: -20px;
        -webkit-transition: color 0.5s ease-in;
        -moz-transition: color 0.5s ease-in;
        -o-transition: color 0.5s ease-in;
        transition: color 0.5s ease-in;

        @media screen and (max-width: $break-medium) {
          font-size: 45px;
          margin-bottom: 0;
          padding-left: 0;
        }
      }

      p {
        font-size: 18px;
        font-weight: bold;
        color: $ice-blue;
        padding: 5px;
        margin: 0;

        @media screen and (max-width: $break-medium) {
          font-size: 14px;
          display: none;
        }
      }
    }

    .eachWayContent {
      display: flex;
      flex-direction: column;

      h3,
      p {
        opacity: 0;
        -webkit-transition: opacity 0.5s ease-in;
        -moz-transition: opacity 0.5s ease-in;
        -o-transition: opacity 0.5s ease-in;
        transition: opacity 0.5s ease-in;

        @media screen and (max-width: $break-medium) {
          display: block;
        }
      }

      h3 {
        font-size: 20px;
        font-family: $NeoSansArabic_font_bold;
        line-height: 1.83;
        letter-spacing: normal;
        color: $slate-grey;
        -webkit-transition: color 0.5s ease-in;
        -moz-transition: color 0.5s ease-in;
        -o-transition: color 0.5s ease-in;
        transition: color 0.5s ease-in;

        @media screen and (max-width: $break-medium) {
          font-size: 18px;
          text-align: right;
          color: $ice-blue;
          opacity: 1;
        }
      }

      p {
        font-size: 14px;
        line-height: 1.89;
        color: $brown-grey;
        width: 80%;

        @media screen and (max-width: $break-large) and (min-width: $break-medium) {
          width: 90%;
        }

        @media screen and (max-width: $break-medium) {
          font-size: 18px;
          margin: 0 auto;
          font-weight: normal;
          font-family: $NeoSansArabic_font_lighter;
        }
      }
    }
  }

  .eachWay-empty {
    height: 150px;
    width: 150px;

    @media screen and (max-width: $break-medium) {
      // width: 250px;
      // height: 300px;
      display: none;
    }
  }

  .slick-slider {
    right: 10%;
    width: 60%;
    top: 5%;
  }
}

// ---------------- end ourWay --------------

@mixin white-gradient-right {
  background: -ms-linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
  background: -o-linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
  background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(calc(250px * 11));
    transform: translateX(calc(250px * 11));
  }

  100% {
    -webkit-transfor: translateX(0);
    transform: translateX(0);
  }
}

@keyframes scroll-right {
  0% {
    -webkit-transfor: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transfor: translateX(calc(250px * 12));
    transform: translateX(calc(250px * 12));
  }
}

// ------------- ourClients -----------
.ourClients {
  text-align: right;
  height: 100%;

  @media screen and (max-width: $break-medium) {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .clients-row-one {
    margin-top: 75px;
  }

  .slider {
    background: #ffffff;
    height: 200px;
    margin: 25px auto;
    overflow: hidden;
    position: relative;
    width: 90%;

    &::before,
    &::after {
      @include white-gradient-right;
      content: "";
      height: 200px;
      position: absolute;
      width: 200px;
      z-index: 2;
    }

    &::after {
      right: 0;
      top: 0;
      -moz-transform: rotateZ(180deg);
      -ms-transform: rotateZ(180deg);
      -webkit-transform: rotateZ(180deg);
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }

    .slide-track-left,
    .slide-track-right {
      display: flex;
    }

    .slide-track-left {
      width: calc(250px * 22);
    }

    .slide-track-right {
      width: calc(250px * 24);
    }

    .slide-track-left {
      -webkit-animation: scroll 50s linear infinite;
      animation: scroll 50s linear infinite;
    }

    .slide-track-right {
      -webkit-animation: scroll-right 50s linear infinite;
      animation: scroll-right 50s linear infinite;
    }

    .slide {
      height: 200px;
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 70%;
        max-height: 90%;
        -webkit-filter: grayscale(100%);
        /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        opacity: 0.8;
        margin: 0 auto;

        &:hover {
          -webkit-filter: grayscale(0);
          /* Safari 6.0 - 9.0 */
          filter: grayscale(0);
          opacity: 1;
        }
      }

      .large {
        max-width: 90%;
        max-height: 90%;
      }

      .small {
        max-width: 55%;
        max-height: 55%;
      }
    }
  }

  .ourClientsDiv {
    @media screen and (max-width: $break-medium) {
      display: none;
    }
  }

  .ourClientsDiv-mobile {
    display: none;

    @media screen and (max-width: $break-medium) {
      display: block;
    }

    width: 80%;
    margin: 0 auto;

    @media screen and (max-width: $break-medium) {
      width: 100%;
    }

    .slick-dots {
      bottom: 4%;

      li {
        margin: 0;

        button {
          &::before {
            color: $very-light-pink;
            opacity: 1;
            font-size: 10px;
          }
        }

        &.slick-active {
          button::before {
            color: $aqua-marine;
            opacity: 1;
          }
        }
      }
    }

    .clientsSection {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 5px;
      padding: 15px 0;
      width: 100%;

      @media screen and (max-width: $break-medium) {
        padding: 20px 0;
      }

      .details {
        // height: 350px;
        overflow: auto;
        margin-bottom: 1rem;
      }

      .projectLogo {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          align-self: center;
          margin: 0 auto;
          padding: 20px 0;
          width: 100px;
        }
      }

      .stores {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 10px 0;

        img {
          margin: 0 auto;
          width: 80%;
        }
      }

      .projectImg {
        width: 90%;
        height: 200px;
        align-self: center;
        margin: 0 auto;
        padding: 20px 0;

        @media screen and (max-width: $break-medium) {
          max-width: 100%;
          max-height: 100%;

          &.small {
            max-width: 65%;
            max-height: 65%;
          }
        }
      }

      .musaned {
        max-width: 75%;
        max-height: 75%;
      }

      p {
        width: 90%;
        margin: 0 auto;
        font-family: $NeoSansArabic_font_lighter;
        font-weight: bold;
        color: $brown-grey;
        font-size: 18px;

        @media screen and (max-width: $break-medium) {
          font-size: 16px;
          margin: 5px auto;
        }

        @media screen and (max-width: 320px) {
          font-size: 14px;
        }
      }
    }
  }
}

// -------------- end ourClients ----------------

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(20px);
  }

  100% {
    transform: translatey(0px);
  }
}

// ------------ teamworks ---------
.teamWorks {
  height: 100%;

  @media screen and (max-width: $break-medium) {
    display: flex;
    flex-direction: column;
  }

  .floatAnimation {
    animation: float 6s ease-in-out infinite;
  }

  .teamWorksContent {
    margin-right: 10%;
    text-align: right;
    z-index: 1;

    @media screen and (max-width: $break-medium) {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 0;
      margin-bottom: 75px;
    }

    h1 {
      padding-top: 8%;
      color: $aqua-marine2;
      font-family: $NeoSansArabic_font_bold;
      font-size: 55px;

      @media screen and (max-width: $break-medium) {
        margin-right: 0;
        padding-top: 100px;
        font-size: 35px;
        padding-bottom: 20px;
        margin: 0;
      }
    }

    p {
      padding-top: 1%;
      font-size: 20px;
      font-family: $NeoSansArabic_font_lighter;
      font-weight: bold;
      color: $brown-grey;
      line-height: 1.67;
      width: 50%;

      @media screen and (max-width: $break-medium) {
        text-align: center;
        font-family: $NeoSansArabic_font;
        font-weight: normal;
        width: 75%;
        top: 10%;
        position: relative;
        margin: 0 auto;
        font-size: 18px;
        line-height: 1.94;
      }
    }
  }

  .teamWorksImage {
    position: relative;
    width: 80%;
    margin: 0 auto;

    @media screen and (max-width: $break-medium) {
      position: unset;
      width: 95%;
    }

    .images {
      display: flex;
      position: relative;
      width: 100%;
      height: 90%;

      @media screen and (max-width: $break-medium) {
        display: none;
      }

      .manCloud,
      .girlCloud {
        width: 56%;

        @media screen and (max-width: $break-medium) {
          max-width: 90%;
          max-height: 90%;
        }
      }

      .girlCloud {
        align-self: flex-end;
        margin-right: -16%;
        margin-bottom: -1.5%;
        // @media screen and (max-width: $break-medium) {
        //   // margin-right: -25%;
        // }
      }

      .manCloud {
        margin-top: -0.5%;

        @media screen and (max-width: $break-medium) {
          margin-right: 2%;
        }
      }
    }

    .cloud {
      position: absolute;
      left: 50px;
      top: 0;
      width: 20%;
    }

    .team-m {
      display: none;

      @media screen and (max-width: $break-medium) {
        display: block;
        margin: 0 auto;
        width: 95%;
      }
    }
  }
}

// ---------- end teamworks ------------

// ----------- news ------------
.news {
  position: relative;
  height: 100%;
  -webkit-animation: fadein 600ms;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 600ms;
  /* Firefox < 16 */
  -ms-animation: fadein 600ms;
  /* Internet Explorer */
  -o-animation: fadein 600ms;
  /* Opera < 12.1 */
  animation: fadein 600ms;
  padding-bottom: 20px;

  .sawah {
    position: absolute;
    top: 1.35%;
    right: 53%;
    width: 30%;
    z-index: -1;

    @media screen and (max-width: $break-large) and (min-width: $break-medium) {
      top: 1.2%;
      right: 55%;
    }

    @media screen and (max-width: $break-medium) {
      display: none;
    }
  }

  .newsSection {
    width: 100%;
    margin: 0 auto;
    padding-top: 10%;
    text-align: right;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $break-medium) {
      text-align: center;
      width: 100%;
      padding-top: 25%;
    }

    .eachNews {
      height: 70%;
      width: 70%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: $break-medium) {
        width: 75%;
        align-items: center;
      }

      .date,
      .dateReverse {
        width: 80px;
        height: 100%;
        border-radius: 5px;
        background-color: rgba(188, 188, 197, 0.23);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin: 0 0 10px 0;
        padding: 8px 0;

        p {
          margin: 0;
          font-size: 20px;
          font-family: $NeoSansArabic_font_bold;
          color: $brown-grey;
        }

        p:nth-child(2) {
          font-size: 20px;
          line-height: 1.2;
        }

        p:first-child {
          margin-bottom: -5px;
        }

        @media screen and (max-width: $break-medium) {
          width: 80px;
          height: 100%;
          border-radius: 5px;
          background-color: rgba(188, 188, 197, 0.23);
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 10px;

          p:nth-child(2) {
            line-height: 1.4;
          }
        }
      }

      .dateReverse {
        align-self: flex-end;
        margin-left: 4%;

        @media screen and (max-width: $break-medium) {
          align-self: center;
        }
      }

      .newsContent {
        padding-top: 10px;

        h1 {
          font-size: 45px;
          font-family: $NeoSansArabic_font_bold;
          line-height: 1.29;
          color: $aqua-marine2;
          width: 70%;
          // margin-bottom: 0;

          @media screen and (max-width: $break-medium) {
            font-size: 35px;
            font-weight: bold;
            line-height: 1.29;
            color: $aqua-marine2;
            width: 100%;
          }
        }

        p {
          font-family: $NeoSansArabic_font_lighter;
          width: 50%;
          font-size: 18px;
          font-weight: 300;
          line-height: 1.25;
          color: $brown-grey;

          a {
            color: inherit;
          }

          @media screen and (max-width: $break-medium) {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.94;
            color: $slate-grey;
            width: 100%;
          }
        }
      }

      .media,
      .mediaReverse {
        display: flex;
        justify-content: space-between;

        .newsImg {
          padding: 10px 0;
        }
      }

      .media {
        width: 95%;

        @media screen and (max-width: $break-medium) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .newsImg {
          text-align: center;
          width: 80%;
          height: 400px;

          @media screen and (max-width: $break-medium) {
            width: auto;
            height: 100%;
          }

          img {
            box-shadow: 0 2px 65px 0 rgba(215, 215, 215, 0.43);
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .mediaReverse {
        flex-direction: row-reverse;
        width: 95%;
        padding-left: 10%;

        @media screen and (max-width: $break-medium) {
          padding-left: 0;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .newsImg {
          width: 85%;
          height: 400px;

          @media screen and (max-width: $break-medium) {
            width: auto;
            height: 100%;
          }

          img {
            box-shadow: 0 2px 65px 0 rgba(215, 215, 215, 0.43);
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .lineContent {
        display: flex;
        width: 80px;
        position: relative;

        @media screen and (max-width: $break-medium) {
          width: auto;
        }

        .contentDiv {
          height: 240px;
          align-self: flex-end;
          margin: 5px;

          @media screen and (max-width: $break-medium) {
            display: none;
          }

          p {
            font-size: 18px;
            font-family: $NeoSansArabic_font_bold;
            color: $cloudy-blue;
            height: 95%;
            margin: 0;
            margin-right: -8%;
            writing-mode: tb-rl;
            transform: rotate(180deg);
          }
        }

        .lineDiv {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-bottom: 10px;

          .lineDots {
            width: 14px;
            height: 14px;
            background-color: $windows-blue;
            border-radius: 100%;
          }

          .line {
            margin-top: -3px;
            width: 2px;
            height: 400px;
            border: solid 1px $windows-blue;

            @media screen and (max-width: $break-medium) {
              width: 1px;
              height: 109px;
              border: solid 1px $windows-blue;
            }
          }
        }
      }
    }
  }

  .socialMediaNews {
    position: fixed;
    top: 75vh;
    right: 1.6%;

    @media screen and (max-width: $break-medium) {
      display: none;
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      padding: 0;

      li {
        padding-bottom: 15px;

        img {
          width: 30px;
        }
      }
    }
  }
}

.footer {
  height: 70vh;
  background-color: white;
  background-image: linear-gradient(to top, $ice-blue, rgba(255, 255, 255, 0));
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: $break-medium) {
    flex-direction: column;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 20px;
  }

  .footerContent {
    display: flex;
    text-align: right;
    margin: 0 auto;
    justify-content: space-between;
    width: 80%;

    @media screen and (max-width: $break-medium) {
      flex-direction: column;
      text-align: center;
    }

    @media screen and (max-width: $break-large) and (min-width: $break-medium) {
      width: 90%;
    }

    div:first-child {
      display: flex;

      @media screen and (max-width: $break-medium) {
        flex-direction: column;
        text-align: center;
      }
    }

    .company,
    .services,
    .connectUs {
      display: flex;
      flex-direction: column;
      padding: 0 50px;
      margin-bottom: 75px;

      @media screen and (max-width: $break-medium) {
        margin-bottom: 25px;
      }

      h5 {
        color: $windows-blue;
        font-family: $NeoSansArabic_font_bold;
        margin-bottom: 25px;

        @media screen and (max-width: $break-medium) {
          margin-bottom: 15px;
        }
      }

      a {
        text-decoration: none;
        color: $brown-grey;
        line-height: 2.3;
        font-size: 20px;
        font-family: $NeoSansArabic_font_lighter;
        cursor: pointer;

        @media screen and (max-width: $break-medium) {
          font-size: 16px;
          line-height: 2.5;
        }
      }
    }

    .company {
      div {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $break-medium) {
          flex-direction: row;
          justify-content: center;
        }
      }

      @media screen and (max-width: $break-medium) {
        display: block;

        a {
          padding: 0 10px;
        }
      }
    }

    .socialMedia {
      ul {
        width: 100%;
        list-style: none;
        display: flex;
        padding: 0;
        margin-bottom: 0.3rem;

        @media screen and (max-width: $break-medium) {
          justify-content: center;
        }

        li {
          padding-left: 15px;

          @media screen and (max-width: $break-medium) {
            padding-left: 0;
            padding: 0 10px;
          }

          a {
            img {
              width: 33px;
            }
          }
        }
      }
      ul:last-child {
        flex-direction: column;
        margin-top: 10px;
        a {
          display: flex;
          p {
            margin: auto 10px;
          }
        }
      }
    }

    .copyright {
      align-self: flex-end;

      @media screen and (max-width: $break-medium) {
        align-self: center;
      }

      div {
        align-self: flex-end;
        text-align: left;

        p {
          color: $brown-grey;
          font-size: 18px;
          font-family: $NeoSansArabic_font_lighter;

          span {
            padding-left: 10px;
          }

          img {
            width: 30px;
          }
        }
      }
    }
  }
}

.en {
  direction: ltr;

  @media screen and (min-width: 769px) {
    .ourVisionContent {
      text-align: left;
      padding-right: 0;
      padding-left: 10%;
    }

    .ourServices,
    .ourWay,
    .ourClients,
    .teamWorks {
      text-align: left;
    }

    .ourWay h1,
    .ourServices h1,
    .ourClients h1 {
      margin-right: 0;
      margin-left: 10%;
    }

    .teamWorksContent {
      text-align: left;
      margin-right: 0;
      margin-left: 10%;
    }

    .footerContent {
      text-align: left;

      .copyright {
        direction: rtl;
      }

      .connectUs .socialMedia li {
        padding-left: 0;
        padding-right: 15px;
      }
    }
  }

  nav {
    direction: rtl;
  }

  .news {
    direction: rtl;
  }

  .logo {
    text-align: left;
    padding-right: 0 !important;
    padding-left: 0.3rem !important;
  }

  .menu-toggle {
    @media screen and (max-width: $break-medium) {
      left: unset;
      right: 18px;
    }
  }

  .navLeft div img {
    margin-right: 0;
    margin-left: -26px;
  }

  .navLeft button {
    margin-right: 0;
    margin-left: 16px;
    width: 150px;
  }

  .startYourProjectButton {
    @media screen and (max-width: $break-medium) {
      font-size: 11px;
      width: 120px;
    }
  }

  .homeHeader h1 {
    font-size: 40px;

    @media screen and (max-width: 769px) {
      font-size: 24px;
      width: unset;
    }
  }

  .home .homeHeader .header br {
    @media screen and (max-width: 1700px) {
      display: block !important;
    }
  }

  .projectDetails {
    text-align: left;

    .service {
      img {
        margin-left: 0;
        margin-right: 0.5rem;
      }
    }
  }

  .ourVisionImg {
    text-align: right;
    right: 0;
    left: unset;
  }

  .ourServices-l-outer {
    right: unset;

    .ourServices-l {
      right: unset;
      margin-left: 8%;
      -webkit-transition: left 500ms;
      -moz-transition: left 500ms;
      -ms-transition: left 500ms;
      transition: left 500ms;
    }

    .eachService {
      height: 100%;

      img {
        height: 36px;
      }

      h5 {
        height: 50px;
      }

      p {
        line-height: 1.7;
      }
    }
  }

  .ourWayContent {
    @media screen and (max-width: $break-medium) {
      direction: rtl;
    }
  }

  .ourWayImgDiv {
    left: unset;
    right: 0;
  }

  .ourWayImgDiv .first-way {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

    @media screen and (max-width: $break-medium) {
      left: unset;
      right: 0;
    }
  }

  .ourWayImg.last-way {
    left: unset;
    right: 15%;

    @media screen and (max-width: $break-medium) {
      left: 50%;
      right: unset;
    }
  }

  .ourWayContent .slider,
  .ourWayContent .sliderContent {
    right: unset;
    left: 6%;

    @media screen and (max-width: 769px) {
      left: unset;
    }
  }

  .ourWayContent .sliderContent {
    @media screen and (max-width: 769px) {
      left: unset;
      right: 6%;
    }
  }

  .eachWay .eachWayNum,
  .eachWay.active .eachWayNum {
    h1 {
      padding-left: 0;
      padding-right: 20px;

      @media screen and (max-width: 769px) {
        padding-right: 0;
      }
    }
  }

  @media screen and (max-width: $break-medium) {
    .ourWay .eachWay .eachWayContent p {
      font-size: 16px;
      direction: ltr;
    }
  }

  .ourClientsDiv,
  .teamWorksImage {
    direction: rtl;
  }

  .infoSection {
    select {
      background-position-x: 650px !important;

      &.error {
        background-position-x: 650px !important;
      }

      @media screen and (max-width: $break-medium) {
        background-position-x: 125px !important;

        &.error {
          background-position-x: 115px !important;
        }
      }
    }

    #projectForm {
      .input.error,
      .textarea-input.error {
        background: url("./images/ic-error.svg") no-repeat right;
        background-position-x: 655px !important;
        background-size: 18px;

        @media screen and (max-width: $break-medium) {
          background-position-x: 270px !important;
        }
      }

      #email.error {
        background-position-x: 655px !important;

        @media screen and (max-width: $break-medium) {
          background-position-x: 270px !important;
        }
      }

      #mobileNumber.error {
        background-position-x: 497px !important;

        @media screen and (max-width: $break-medium) {
          background-position-x: 168px !important;
        }
      }

      #countryCode.error {
        background-position-x: 115px !important;

        @media screen and (max-width: $break-medium) {
          background-position-x: 58px !important;
        }
      }

      .errorMessage {
        text-align: left;
      }

      .mobileNumber {
        flex-direction: row-reverse;
      }

      #countryCode {
        text-align: left;
      }
    }
  }

  .copyright {
    span {
      padding-left: 0;
      padding-right: 10px;
    }
  }
}

.click-marsool {
  font-size: 14px !important;
  bottom: 15%;
  right: 33%;
  cursor: pointer;

  @media screen and (max-width: 1024px) and (max-height: 1366px) {
    bottom: 7%;
  }

  @media screen and (max-width: 1024px) and (max-height: 768px) {
    bottom: 12%;
  }

  @media screen and (max-width: 1024px) and (max-height: 768px) {
    bottom: 12%;
  }

  @media screen and (max-width: 1366px) and (max-height: 1024px) {
    bottom: 11%;
  }
}

.click-effect,
.click-effect:after {
  background-color: $aqua-marine;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.click-effect {
  position: absolute;
  // z-index: 999;
  display: block;
  height: 8px;
  width: 8px;

  @media screen and (max-width: $break-medium) {
    display: none;
  }
}

// .click-effect-lg {
// 	position: relative;
// 	display: block;
// 	height: 20px;
// 	width: 20px;
// }
.click-effect:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  content: "";
  height: 22px;
  width: 22px;
  margin: -11px 0 0 -11px;
  animation: pulsate 2s linear;
  -webkit-animation: pulsate 2s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.parallax-class {
  position: absolute;
  width: 100%;
  height: 100%;

  .parallax-inner {
    height: 100%;
  }
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.05;
  }

  // 20% {
  //     -webkit-transform: scale(0.7);
  //     opacity: .1;
  // }
  20% {
    -webkit-transform: scale(0.9);
    opacity: 0.2;
  }

  30% {
    -webkit-transform: scale(1.1);
    opacity: 0.3;
  }

  50% {
    -webkit-transform: scale(1.4);
    opacity: 0;
  }

  80% {
    -webkit-transform: scale(1.4);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1.4);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0);
    opacity: 0.05;
  }

  // 20% {
  //     transform: scale(0.7);
  //     opacity: .1;
  // }
  20% {
    transform: scale(0.9);
    opacity: 0.2;
  }

  30% {
    transform: scale(1.1);
    opacity: 0.3;
  }

  50% {
    transform: scale(1.4);
    opacity: 0;
  }

  80% {
    transform: scale(1.1);
    opacity: 0;
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
